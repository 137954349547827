/* fonts included */
@import '../src/assets/scss/fonts';

body {
  margin: 0;
  background-color: #000;
  font-family: SourceSansPro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
