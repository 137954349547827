@import '../../../assets/scss/colors';
@import '../../../assets/scss/dimensions';

.empty-guide-container {
  display: flex;
  align-items: center;
  height: 100vh;
  margin-left: 80px;

  .footer-container {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
